<template>
  <div class="find-work">
    <line-title
      :title="`发现你感兴趣的职位`"
      :color="`#333439`"
      :lineColor="`#C4C4C4`"
    ></line-title>
    <div class="work-classify w1120">
      <div class="work-name">职位类别</div>
      <div class="work-content_O1">
        <CustomTab :option="optionData" :selectIndex.sync="selectIndex">
        </CustomTab>
        <tab1 :jobMessage="jobMessage"></tab1>
      </div>
    </div>
  </div>
</template>

<script>
import CustomTab from "../../../../../components/custom-tab/index.vue";
import tab1 from "@/views/index/views/joinus/components/tab1.vue";
import { selectAll, selectJobMessage } from "@/api";
export default {
  data() {
    return {
      optionData: [],
      componentsMap: [],
      selectIndex: 0,
      jobMessage: {},
      jobMessageMap: {},
    };
  },
  async created() {
    let res = await selectAll();
    res.data.data.map((item) => {
      this.optionData.push(item.jobCategoryName);
      this.componentsMap.push(item.jobCategoryId);
    });
    this.getJobMessage(this.jobId);
  },
  computed: {
    jobId() {
      return this.componentsMap[this.selectIndex];
    },
  },
  watch: {
    selectIndex() {
      this.getJobMessage(this.jobId);
    },
  },
  methods: {
    async getJobMessage(jobId) {
      if (this.jobMessageMap[this.selectIndex]) {
        this.jobMessage = this.jobMessageMap[this.selectIndex];
      } else {
        let res = await selectJobMessage(jobId);
        this.jobMessage = res.data.data[0];
        this.jobMessageMap[this.selectIndex] = this.jobMessage;
      }
    },
  },
  components: {
    CustomTab,
    tab1,
  },
};
</script>

<style lang="scss" scoped>
.find-work {
  margin-top: 40px;
  .work-classify {
    margin-top: 14px;
    .work-name {
      font-size: 22px;
      color: #000;
      font-weight: bold;
    }
  }
  .work-content_O1 {
    display: flex;
    margin-left: 10px;
    // margin-top: 26px;
  }
}
</style>
