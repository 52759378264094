<template>
  <div class="order-way w1120">
    <div class="way" :style="styleCss">
      <div class="item">
        <div class="icon">
          <img src="@/assets/phone.svg" alt="" />
        </div>
        <div class="text">159-4921-0837</div>
      </div>
      <div class="item">
        <div class="icon">
          <img src="@/assets/position.svg" alt="" />
        </div>
        <div class="text">lintech@linktwins.com</div>
      </div>
      <div class="item">
        <div class="icon">
          <img src="@/assets/map.svg" alt="" />
        </div>
        <div class="text">无锡经开区高浪路999号（无锡国家传感信息中心）D1栋901室</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    left: {
      type: String,
      default: "842",
    },
  },
  computed: {
    styleCss() {
      return {
        "--marginLeft": this.left,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.order-way {
  padding-bottom: 46px;
  .way {
    margin-top: 33px;
    // margin-left: calc(var(--marginLeft) / 19.2 * 1vw);
    margin-left: 245px;
    .item {
      display: flex;
      margin-bottom: 30px;
      align-items: center;
      .icon,
      .text {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 20px;
        height: 20px;
      }
      .text {
        font-size: 14px;
        margin-left: 15px;
      }
    }
  }
}
</style>
