<template>
  <div class="tab-content">
    <div class="tab-center" v-if="!isHaveJob">
      <div class="center-img"></div>
      <div class="center-text">此类别暂无，请尝试其他选择</div>
    </div>
    <template v-else>
      <div class="tab-header">{{ jobMessage.positionName }}</div>

      <div class="tab-bottom">
        <p>
          {{ jobMessage.workplace }}
          <span style="margin-left: 14px;">{{
            jobMessage.positionType == 0 ? "全职" : "兼职"
          }}</span>
        </p>
        <div class="job-res">
          <div class="title">岗位职责</div>
          <p
            v-for="(item, index) in positionResponsibility"
            :key="index + 'respon'"
          >
            {{ item }}
          </p>
        </div>
        <div class="job-need">
          <div class="title">岗位要求</div>
          <p v-for="(item, index) in positionRequest" :key="index + 'request'">
            {{ item }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    isHaveJob() {
      if (Object.keys(this.jobMessage).length === 0) return false;
      return true;
    },
    positionResponsibility() {
      if (this.jobMessage?.positionResponsibility) {
        let res = this.jobMessage.positionResponsibility.split("/n");
        return res;
      } else {
        return [];
      }
    },
    positionRequest() {
      if (this.jobMessage?.positionRequest) {
        let res = this.jobMessage.positionRequest.split("/n");
        return res;
      } else {
        return [];
      }
    },
  },
  props: {
    jobMessage: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
};
</script>
