<template>
  <div class="join-us">
    <div class="join-bg">
      <div class="join-img">
        <div class="text-area">
          <h1>加入零探</h1>
          <p>
            让数字可见，让未来可现，你的元宇宙由你定义！<br />
            在零探，你将与聪慧、优秀的小伙伴们，共同成长！<br />
            奔跑向前，你我共同梦圆坦途！
          </p>
        </div>
        <div class="banner"></div>
      </div>
    </div>
    <find-work></find-work>
    <order-way :left="`248`"></order-way>
  </div>
</template>

<style lang="scss" scoped>
.join-bg {
  width: 100%;
  height: 928px;
  background-size: cover;
  // background-attachment: fixed;
  background-position: center 0;
  background-image: url("../../../../assets/banner-bg.png");
  background-repeat: no-repeat;
}
.join-img {
  display: flex;
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  // background-size: cover;
  .text-area {
    margin-top: 331px;
    h1 {
      color: #333439;
      font-weight: 700;
      font-size: 48px;
    }
    p {
      margin-top: 36px;
      width: 534px;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #333439;
    }
    .goWorld {
      margin-top: 57px;
    }
  }
  .banner {
    width: 666px;
    height: 100%;
    background-size: 100% 100%;
    background-image: url("../../../../assets/index-bg2.png");
  }
  // .join-text {
  //   position: relative;
  //   top: 124px;
  //   left: 493px;
  //   font-size: 40px;
  //   font-weight: bold;
  //   background-image: -webkit-linear-gradient(#e69f2b 0%, #d75529 100%);
  //   background-clip: text;
  //   -webkit-text-fill-color: transparent;
  //   -webkit-text-stroke: 1px #ffffff;
  // }
}
</style>

<script>
import findWork from "./components/find-work.vue";
import OrderWay from "../learn/components/product-introduce/components/order-way/index.vue";
export default {
  components: { findWork, OrderWay },
};
</script>
